import React from "react"
import {Link, graphql} from "gatsby";
import Layout from "../components/layout"
import SEO from "../../plugins/gatsby-theme-chegatsby/src/components/seo"
import {Col, Container, Row} from "react-bootstrap"

const NotFoundPage = () => (
  
      <Layout pageInfo={{pageName: "index"}}>
        <SEO title="OOOPS 404" keywords={[`Musica per cerimonie ed eventi`, `Ciro Triassi`, `Agenzia Musicale Salerno`, `Musica per Matrimonio`, `Animazione per Matrimonio`, `Agenzia Eventi e Spettacoli Napoli`, `Agenzia Eventi e Spettacoli Salerno`, `Ciro Triassi Music Live`]}/>

        <Container className="my-4">
            <h5 className="text-center">CIRO TRIASSI MUSIC LIVE</h5>
            <h1 className="mb-4 text-center">OOOPS 404</h1>
            <Row className="my-4">
                <Col>
                    <p className="text-center">Cercavi della buona musica per un evento o una promozione?<br/>Dai un occhio a queste pagine...</p>
					
					    <Link to="/chi-siamo" className="link-no-style">
                            <h5 className="my-4 text-center">
                                Chi siamo
                            </h5>
                        </Link>
                        <Link to="/promozioni" className="link-no-style">
                            <h5 className="my-4 text-center">
                                Promozioni
                            </h5>
                        </Link>
                        <Link to="/video-portfolio" className="link-no-style">
                            <h5 className="my-4 text-center">
                                Video
                            </h5>
                        </Link>
                </Col>
            </Row>
        </Container>
    </Layout>
  
  
)

export default NotFoundPage